import React, { useState, useEffect } from 'react';
import { PageProps, Link, graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Helmet } from 'react-helmet'
import Container from "../components/pages/container/container"
import ContainerFluid from "../components/pages/container/container-fluid"
import Footer from "../components/Footer"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from "gbimage-bridge"
//import LandingTestimonialSlider from 'components/sliders/landing-testimonial-slider'
import TranslationTestimonialSlider from 'components/sliders/translation-testimonial-slider'

import "../scss/transportationstyle.scss"

type DataProps = {
  data: IndexQueryQuery
  location: Location
}

const TransportationPage: React.FC<PageProps<DataProps>> = ({ location }) => {

  const data = useStaticQuery(
    graphql`
      query {
        hero_background: file(relativePath: { eq: "infinity-icon-small1.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:1400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        hero_img: file(relativePath: { eq: "landing-hero-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:1400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        car_hero: file(relativePath: { eq: "car-hero.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:50
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        hipaa_icon: file(relativePath: { eq: "hipaa-icon.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        reliable_icon: file(relativePath: { eq: "reliable-icon.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        cost_icon: file(relativePath: { eq: "cost-icon.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        middle_img: file(relativePath: { eq: "middle-line.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        less_schedule_img: file(relativePath: { eq: "landing-section5-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        commitment_img: file(relativePath: { eq: "landing-section5-1img.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        section7_img: file(relativePath: { eq: "landing-section7-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        btn_img: file(relativePath: { eq: "btn-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )

  //declare image Data
  const hero_background = getImage(data.hero_background)
  const hero_img = getImage(data.hero_img)
  const car_hero = getImage(data.car_hero)
  const cost_icon = getImage(data.cost_icon)
  const reliable_icon = getImage(data.reliable_icon)
  const hipaa_icon = getImage(data.hipaa_icon)
  const middle_img = getImage(data.middle_img)
  const less_schedule_img = getImage(data.less_schedule_img)
  const commitment_img = getImage(data.commitment_img)
  const section7_img = getImage(data.section7_img)
  const btn_img = getImage(data.btn_img)

  return (
    <>
      <Helmet bodyAttributes={{
        class: 'transportation_body'
      }}>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      </Helmet>
      <Layout>
        <Seo
          title="Non-Emergency Medical Transportation Service"
          description="Transcend is a non-emergency medical transportation service available in all 50 states. We provide reliable and affordable transport to patients who require assistance with their daily tasks. For more information, visit our website!"
        />
        <div className="backround-gradient">
          <section id="landing-hero-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 hero-title">
                  <h1 className="text-center">
                    TRANSCEND <br />TRANSPORTATION SERVICES
                  </h1>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 center-text">
                  <GatsbyImage
                    image={hero_img}
                    alt=""
                    formats={["AUTO", "WEBP", "AVIF"]}
                    className="hero-img"
                  />
                </div>
                <div className="col-lg-6 right-content">
                  <p className="title text-white">
                    The Right Care Starts with the Right Ride
                  </p>
                  <p className="sub-title text-white">
                    Use Transcend's online dashboard to book transportation and manage appointments with ease.
                  </p>
                  <button className="get-btn text-white" data-bs-toggle="modal" data-bs-target="#getStartedPopupModal">Get Started</button>
                </div>
              </div>
            </div>
          </section>
          <section id="landing-section-1">
            <Container>
              <div className="col-lg-12">
                <h2 className="text-center">Enabling Third-Party <br />Administrators to Arrange Rides <br />for Their Claimant's</h2>
              </div>
            </Container>
          </section>
          <section id="landing-section-2" className="text-center">
            <Container>
              <div className="col-lg-4">
                <GatsbyImage
                  image={hipaa_icon}
                  alt=""
                  formats={["AUTO", "WEBP", "AVIF"]}
                  className="section2-icon"
                />
                <p className="text-white title text-center">
                  HIPAA COMPLIANT SECURITY
                </p>
                <p className="text-white text-center section2-content">
                  Secure patient details while<br /> effortlessly coordinating <br />transportation.
                </p>
              </div>
              <div className="col-lg-4">
                <GatsbyImage
                  image={cost_icon}
                  alt=""
                  formats={["AUTO", "WEBP", "AVIF"]}
                  className="section2-icon"
                />
                <p className="text-white title text-center">
                  COST-EFFECTIVE. <br />TIME-EFFICIENT
                </p>
                <p className="text-white text-center section2-content">
                  Minimize your operating costs. The <br />online dashboard streamlines<br /> transportation booking.
                </p>
              </div>
              <div className="col-lg-4">
                <GatsbyImage
                  image={reliable_icon}
                  alt=""
                  formats={["AUTO", "WEBP", "AVIF"]}
                  className="section2-icon"
                />
                <p className="text-white title text-center ">
                  COMPLETELY <br />RELIABLE
                </p>
                <p className="text-white text-center section2-content">
                  Get there on time. Transcend has maintained a 99.96% reliability rating <br />since 2011.
                </p>
              </div>
            </Container>
          </section>
          <section id="landing-section-3">
            <Container>
              <div className="col-lg-12">
              </div>
              <div className="col-lg-12">
                <h2 className="text-white text-center">EVERY MODE OF TRANSPORTATION</h2>
                <p className="text-white text-center">
                  Book any type of vehicle. Transcend offers air and ground transportation for every <br />non-emergency situation.
                </p>
              </div>
              <div className="row transportation">
                <div className="col-lg-5 first-col">
                  <p className="first-left text-white">LOCAL NON-EMERGENCY MEDICAL TRANSPORT</p>
                  <p className="second-left text-white">MULTI-PATIENT TRANSPORT AMBULANCE</p>
                  <p className="third-left text-white">COMMERCIAL MEDICAL ESCORTS</p>
                </div>
                <div className="col-lg-2 second-col text-center">
                  <GatsbyImage
                    image={middle_img}
                    alt=""
                    formats={["AUTO", "WEBP", "AVIF"]}
                    className="middle-img"
                  />
                </div>
                <div className="col-lg-5 third-col">
                  <p className="first-right text-white">LONG DISTANCE AMBULANCE</p>
                  <p className="second-right text-white">MEDICAL AIR AMBULANCE</p>
                </div>
              </div>
              <div className="col-lg-12">
                <p className="middle-content text-white text-center">AND MORE...</p>
                <button className="reserve-btn text-white" data-bs-toggle="modal" data-bs-target="#getStartedPopupModal" >RESERVE A RIDE</button>
              </div>
            </Container>
          </section>
          <section id="landing-section-4">
            <Container>
              <div className="col-lg-12 mb-4">
                <h2 className="text-white text-center">Every Type of Appointment</h2>
                <p className="text-white text-center">
                  Travel anywhere. Transcend is available for all appointment types.
                </p>
              </div>
              <div className="col-lg-6 left-content">
                <ul>
                  <li className="text-white">DOCTOR VISITS</li>
                  <li className="text-white">HOSPITAL VISITS</li>
                  <li className="text-white">LEGAL COUNSIL</li>
                  <li className="text-white">I.M.E.S</li>
                  <li className="text-white">PT/OT</li>
                  <li className="text-white">ELDERLY AND ASSISTED TRANSPORTATION</li>
                </ul>
              </div>
              <div className="col-lg-6">
                <ul>
                  <li className="text-white">AIRPORT PICK-UP AND DELIVERY</li>
                  <li className="text-white">SPECIAL EVENTS</li>
                  <li className="text-white">GROUP TRIPS</li>
                  <li className="text-white">DIALYSIS</li>
                  <li className="text-white">F.C.E.S</li>
                </ul>
              </div>
            </Container>
          </section>
          <section id="landing-section-5">
            <Container>
              <div className="col-lg-6">
                <GatsbyImage
                  image={less_schedule_img}
                  alt=""
                  formats={["AUTO", "WEBP", "AVIF"]}
                  className="section5-img"
                />
              </div>
              <div className="col-lg-6">
                <h2>LESS RESCHEDULE</h2>
                <p className="text-white">
                  Achieve a higher rate of success. Transcend gets people to their appointments that otherwise wouldn't be able to make it.
                </p>
              </div>
              <div className="col-lg-12 riders">
                <h2 className="text-center text-white mt-10">EASY FOR RIDERS. EASY FOR YOU</h2>
                <p className="text-center text-white">
                  I have a large caseload and I count on Transcend to take care of my transportation and interpretation needs. They go above and beyond to make my job just a little easier. They have outstanding customer service representatives that provide me with proactive communication and truly understand the importance of the appointments that my injured workers need to attend. I have met several Transcend drivers and interpreters and they always conduct themselves professionally and provide a high quality of service. I would highly recommend Transcend to anyone needing such services.
                </p>
              </div>
              <div className="col-lg-6">
                <GatsbyImage
                  image={commitment_img}
                  alt=""
                  formats={["AUTO", "WEBP", "AVIF"]}
                  className="section5-img"
                />
              </div>
              <div className="col-lg-6 right-content">
                <h2>OUR COMMITMENT TO YOUR EXPERIENCE</h2>
                <p className="text-white">
                  Transcend has put customer experience as the highest priority since day one. We strive to exceed all expectations to provide the very best service to our clients.
                </p>
              </div>
            </Container>
          </section>
          <section id="testimonials-section">
            <Container>
              <div className="col-lg-12 testimonial-header-container">
                <h2>Easy for Clients. Easy for You </h2>
              </div>
              <TranslationTestimonialSlider />
            </Container>
          </section>
          <section id="landing-section-7">
            <Container>
              <div className="col-lg-7 get-content">
                <GatsbyImage
                  image={section7_img}
                  alt=""
                  formats={["AUTO", "WEBP", "AVIF"]}
                  className="section7-img section7-mobile"
                />
                <h2>GET STARTED</h2>
                <p className="text-white">
                  Ready to book a driver? We've got you covered. Hit get started below and our team will get you set up with everything you need.
                </p>
                <button className="gets-btn text-white" data-bs-toggle="modal" data-bs-target="#getStartedPopupModal">
                  GET STARTED
                </button>
              </div>
              <div className="col-lg-5">
                <GatsbyImage
                  image={section7_img}
                  alt=""
                  formats={["AUTO", "WEBP", "AVIF"]}
                  className="section7-img"
                />
              </div>
            </Container>
          </section>
          <Footer />
        </div>
      </Layout>
    </>
  )
}

export default TransportationPage
